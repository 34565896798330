<template>
  <section fluid>
    <v-row class="">
      <v-col cols="12">
        <v-list-item v-if="isDuiStatCmp && !valid_dui && idTipoDocumento == 1">
          <v-list-item-content>
            <v-list-item-title>{{ textinputL }}</v-list-item-title>
            <v-list-item-subtitle class="text--error">
              {{ doc_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-row>
          <v-col cols="4">
            <span class="d-flex align-center">
              <v-text-field
                ref="txt_doc_number"
                :label="numeroDocumentoLabel ?? textinputL"
                placeholder="Ingrese el número del documento"
                outlined
                v-model="doc_number"
                :error-messages="docNumberErrors"
                @input="$v.doc_number.$touch()"
                class="input"
                :maxLength="50"
                @change="resetValidation()"
              />
            </span>
          </v-col>
          <v-col v-if="isDuiStatCmp" cols="4">
            <v-btn
              :disabled="!doc_number || !$refs?.txt_doc_number?.valid"
              @click="verifyDui"
              class="mt-4 btnGreen white--text"
            >
              Verificar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="dateinputL != null" cols="12" class="mt-n5">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDate"
              :label="dateinputL"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              :error-messages="dateErrors"
              @blur="$v.date.$touch()"
              class="input"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="menu = false"
            v-model="date"
            no-title
            scrollable
            :min="![2, 4, 10, 23].includes(idTipoDocumento) ? fecha_actual : null"
            :max="idTipoDocumento === 4 ? fecha_actual : null"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="libroRegistroinputL != null" cols="12" class="mt-n5">
        <v-text-field
          v-model="libro_registro"
          :label="libroRegistroinputL"
          placeholder="Libro de registro"
          outlined
          :error-messages="libro_registroErrors"
          @change="$v.libro_registro.$touch()"
          @blur="$v.libro_registro.$touch()"
          class="input"
          :maxLength="50"
        />
      </v-col>

      <v-col class="mt-n5" cols="12">
        <v-file-input
          class="input"
          :label="fileinputL"
          :value="document"
          outlined
          accept=".pdf"
          :prepend-icon="null"
          prepend-inner-icon="mdi-paperclip"
          :error-messages="documentErrors"
          @input="$v.document.$touch()"
          @focus="hideDoc_name = false"
          @blur="hideDoc_name = true"
          @change="validateFileSize($event)"
        >
          <template v-if="doc_route" v-slot:append>
            <!-- tooltip con bonton de descargar -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  style="cursor: pointer"
                  @click="descargar_documento"
                  color="green"
                  >mdi-file-download</v-icon
                >
              </template>
              <span>Descargar</span>
            </v-tooltip>
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          @click="verifyProviderDocument"
          class="btnGreen white--text"
        >
          {{ is_read_only ? "Editar" : "Agregar" }}
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import LegacyValidations from "@/utils/legacy-validations";
import { obtenerErrorDocumentoRequerido } from '@/utils/proveedores';
import {
  required,
  numeric,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation";

function setDocValidation(value) {
  if (this.idTipoDocumento == 5) {
    const regMatriculaComercio = /^\d{1,30}$/;
    if (value) {
      return regMatriculaComercio.test(value);
    }
    return true;
  } else {
    const regDocFormat = /^[a-zA-Z0-9_-]*$/;
    if (value) {
      return regDocFormat.test(value);
    }
    return true;
  }
}

const validarFormatoRegex = (formato) => {
  const validaciones = formato.split('|');
  const expresiones = validaciones.map((validacion) => {
    return new RegExp(`^${validacion.replace(/#/ig, '\\d')}$`);
  });

  return (value) => expresiones.some((expresion) => expresion.test(value));
};

export default {
  name: "FormPanelComponent",
  props: {
    fileinputL: {
      type: String,
      default: null,
    },
    textinputL: {
      type: String,
      default: null,
    },
    dateinputL: {
      type: String,
      default: null,
    },
    idTipoDocumento: {
      type: Number,
      default: null,
    },
    mask: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: null,
    },
    libroRegistroinputL: {
      type: String,
      default: null,
    },
    numeroDocumentoLabel: {
      type: String,
      default: null,
    },
  },
  validations() {
    if (this.dateinputL) {
      return {
        date: { required },
        doc_number: { required, setDocValidation, ...this.format && ({ regex: validarFormatoRegex(this.format, this.inputNameFormatted) }) },
        document: { required },
        ...this.libroRegistroinputL && ({ libro_registro: { required, maxLength: maxLength(50) } }),
      };
    }

    return {
      doc_number: { required, setDocValidation, ...this.format && ({ regex: validarFormatoRegex(this.format, this.inputNameFormatted) }) },
      document: { required },
      ...this.libroRegistroinputL && ({ libro_registro: { required, maxLength: maxLength(50) } }),
    };
  },
  data: () => ({
    date: null,
    menu: false,
    document: null,
    doc_number: null,
    is_read_only: false,
    fecha_actual: moment(Date.now()).format("YYYY-MM-DD"),
    id_to_edit: null,
    valid_dui: false,
    btnVerifyLoading: false,
    doc_route: null,
    doc_name: null,
    hideDoc_name: true,
    documento_loading: false,
    archivo_subido: false,
    loading: false,
    // 
    libro_registro: null,
  }),
  computed: {
    ...mapState("proveedores", ["provider"]),
    formattedDate() {
      if (this.date)
        return moment(this.date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return null;
    },
    isDuiStatCmp() {
      return (
        this.idTipoDocumento == 1 ||
        this.idTipoDocumento == 7 ||
        this.idTipoDocumento == 9
      );
    },
    inputNameFormatted() {
      return this.textinputL.toLowerCase().slice(0, -1);
    },
    docNumberErrors() {
      const errors = [];
      if (!this.$v.doc_number.$dirty) return errors;

      !this.$v.doc_number.required && errors.push(`El campo ${this.inputNameFormatted} es obligatorio`);

      if (this.idTipoDocumento == 5) {
        !this.$v.doc_number.setDocValidation && errors.push(`El campo ${this.inputNameFormatted} solo admite caracteres numéricos y un máximo de 30 caracteres`);
      }
      
      !this.$v.doc_number.setDocValidation && errors.push(`El campo ${this.inputNameFormatted} solo admite alfanuméricos`);
      
      if (this.format && !this.$v.doc_number.regex) {
        errors.push(`El campo ${this.inputNameFormatted} debe seguir el formato: ${this.format.split('|').join(' o ')}`);
      }

      return errors;
    },
    libro_registroErrors() {
      return new Validator(this.$v.libro_registro).detect().getResult();
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;

      // if (this.idTipoDocumento === 4) {
      //   !this.$v.date.required && errors.push("El campo Fecha de constitución es obligatorio");
      // }

      // !this.$v.date.required && errors.push("El campo Fecha de vencimiento es obligatorio");

      !this.$v.date.required && errors.push(obtenerErrorDocumentoRequerido(this.fecha_label));

      return errors;
    },
    documentErrors() {
      const errors = [];
      if (!this.$v.document.$dirty) return errors;
      !this.$v.document.required &&
        errors.push("El campo Documento es obligatorio");
      return errors;
    },
  },
  methods: {
    ...mapActions("proveedores", [
      "saveProviderDocument",
      "updateProviderDocument",
      "getProvider",
    ]),

    validateDocFtn(value) {
      if (this.mask == "########-#") {
        if (this.isDui(value)) {
          return true;
        } else {
          return "El formato del DUI es incorrecto";
        }
      } else if (this.mask == "####-######-###-#") {
        if (this.isNit(value)) {
          return true;
        } else {
          return "El formato del NIT es incorrecto";
        }
      } else {
        return true;
      }
    },

    async verifyProviderDocument() {
      this.$v.$touch();
      if (!this.is_read_only && !this.$refs?.txt_doc_number?.valid) {
        this.temporalAlert({
          show: true,
          message: `El documento ingresado es inválido`,
          type: "warning",
        });
      }

      if (!this.$v.$invalid) {
        this.loading = true;
        let formData = new FormData();

        formData.append("id_tipo_documento", this.idTipoDocumento);
        formData.append("numero_documento", this.doc_number);

        if (this.dateinputL) formData.append("fecha_expiracion", this.date);

        if (this.libroRegistroinputL) formData.append("libro_registro", this.libro_registro);

        formData.append("documento", this.document);
        let response = null;

        if (this.is_read_only)
          response = await this.updateProviderDocument({
            id: this.id_to_edit,
            data: formData,
          });
        else response = await this.saveProviderDocument(formData);

        if (response?.status == 201 || response?.status == 200) {
          this.temporalAlert({
            show: true,
            message: `Documento ${
              this.is_read_only ? "actualizado" : "agregado"
            } con éxito`,
            type: "success",
          });
          await this.getProvider();
          this.compareExistingDoc();
          this.is_read_only = true;
        }
        this.loading = false;
      }
    },
    async compareExistingDoc() {
      const found = this.provider.documentos.find(
        (el) => el.id_tipo_documento === this.idTipoDocumento
      );

      if (found) {
        this.doc_number = found.numero_documento;
        this.date = found.fecha_vencimiento;
        this.id_to_edit = found.id;
        this.doc_route = found.documento;
        this.doc_name = found.nombre_documento;
        this.is_read_only = true;
        this.libro_registro = found.libro_registro;
        this.obtener_documento();
      }
    },
    async obtener_documento() {
      this.documento_loading = true;
      const response = await this.services.Proveedores.getDocumentoProveedor({
        documento: this.doc_route,
      });
      if (response.status === 200) {
        // Convertir en un archivo el arraybufer de response punto data y asignarlo a this.document
        this.document = new File([response.data], this.doc_name, {
          type: "application/pdf",
        });
        this.hideDoc_name = false;
        this.archivo_subido = true;
      }
      this.documento_loading = false;
    },
    validateFileSize(e) {
      const hasValidSize = LegacyValidations.maxFileSizeRule()(e);
      if (!hasValidSize) {
        this.temporalAlert({
          show: true,
          message: "El archivo no puede pesar más de 250MB",
          type: "error",
        });
        this.document = [];
      } else if (e && e?.type !== "application/pdf") {
        this.temporalAlert({
          show: true,
          message: "Solo se permiten archivos con extensión .pdf",
          type: "error",
        });
        this.document = [];
      } else {
        this.document = e;
      }
    },
    resetValidation() {
      if (this.isDuiStatCmp && !this.doc_number) {
        this.valid_dui = false;
      }
    },
    async verifyDui() {
      if (this.doc_number != null) {
        // activamos el loading del botón verificar
        this.btnVerifyLoading = true;


        const response =
          await this.services.VerificarDocumento.verificarDocumento(
            this.doc_number
          );
        this.btnVerifyLoading = false;

        // Si la petición no encontró datos
        if (response.status === 404) {
          this.temporalAlert({
            show: true,
            message: "DUI no válido",
            type: "error",
          });
        }

        if (response.status === 200) {
          this.valid_dui = true;
          this.temporalAlert({
            show: true,
            message: "DUI válido",
            type: "success",
          });
        }

      }
    },
    async descargar_documento() {
      const response = await this.services.Proveedores.getDocumentoProveedor({
        documento: this.doc_route,
      });
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.doc_name}`);
      document.body.appendChild(link);
      link.click();
    },
  },
  created() {
    this.compareExistingDoc();
  },
};
</script>

<style scoped>
.input {
  width: 400px;
}
</style>
